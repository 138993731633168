import React, { useState } from 'react';
import { Nav, Navbar } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';
import { Link } from "react-router-dom";

export function LightNavBar() {
    const [open, setOpen] = useState(false);
    return (
        <Navbar className="header_menu p-0" expand="lg">
            <div className="container-fluid padding_80">
                <div style={{ width: "100%" }}>
                    <div className={`row top_nav_bar ${open ? "menu_up_click" : ""}`}>
                        <div className='col-3 col-md-4 menu_icon_btn'>
                            <button className="menu_icon_btn"
                                onClick={() => setOpen(!open)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                            >

                                {open ? (
                                    // <i className="fas fa-times me-2"></i>
                                    <svg className="me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.63574 18.3643L18.3637 5.63634" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" />
                                        <path d="M6 6L18.7279 18.7279" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                ) : (
                                    // <i className="fas fa-bars me-2"></i>

                                    <svg className="me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 7H21" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" />
                                        <path d="M3 17H21" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                )}
                                <span className='dis_hide'> Menu </span>

                            </button>
                        </div>

                        <div className='col-6 col-md-4 logo_col'>
                            <Link to={"/"}>
                                <img src="/images/logo_light.svg" alt="Logo" />
                            </Link>

                        </div>

                        <div className='col-3 col-md-4 contact_us_menu'>
                            <Link class="nav-link" to={"/contact-us"}>
                                <span className='dis_hide'> Contact us </span>
                                <div className='dis_show'>
                                    <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23 0.644531H3C1.625 0.644531 0.5125 1.76953 0.5125 3.14453L0.5 18.1445C0.5 19.5195 1.625 20.6445 3 20.6445H23C24.375 20.6445 25.5 19.5195 25.5 18.1445V3.14453C25.5 1.76953 24.375 0.644531 23 0.644531ZM23 18.1445H3V5.64453L13 11.8945L23 5.64453V18.1445ZM13 9.39453L3 3.14453H23L13 9.39453Z" fill="white" />
                                    </svg>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className='row collapse_menu_list'>
                        <div className='col-md-12'>
                            <Collapse in={open}>
                                <Nav className="justify-content-center" >
                                    <div className="nav-item" onClick={() => setOpen(!open)}>
                                        <Link class="nav-link" to={"/who-we-are"}> who we are </Link>
                                    </div>
                                    <div className="nav-item" onClick={() => setOpen(!open)}> 
                                        <Link class="nav-link" to={"/what-we-do"}> What we do </Link>
                                    </div>
                                    <div className="nav-item" onClick={() => setOpen(!open)}>
                                        <Link class="nav-link" to={"/our-portfolio"}> Portfolio </Link>
                                    </div>
                                    <div className="nav-item" onClick={() => setOpen(!open)}>
                                        <Link class="nav-link" to={"/news"}> News </Link>
                                    </div>
                                    <div className="nav-item" onClick={() => setOpen(!open)}>
                                        <Link class="nav-link" to={"/contact-us"}> Contact </Link>
                                    </div>
                                </Nav>
                            </Collapse>
                        </div>
                    </div>
                </div>
            </div>
        </Navbar>
    )
}

