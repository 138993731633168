import React from 'react'
import { useAlert } from 'react-alert'

export default function LoginPage() {
    const alert = useAlert();

    const userinfo = {
        username: "twsc_user@apsissolutions.com",
        password: "Sh:I96};T?p~b:F"
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        var { uname, pass } = document.forms[0];
        uname = uname.value;
        pass = pass.value;
        if (uname === userinfo.username && pass === userinfo.password) {
            localStorage.setItem("userinfo", JSON.stringify(userinfo));
            window.location.replace('/');
        }else{
            alert.show('Incorrect Credentials!', {
                timeout: 2000,
                type: 'error'
            })
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <div className='login_page'>
                <div class="park_wrapper">
                    <div class="square blue-dark left-bottom park_animation"> </div>
                    <div class="square red top-right park_animation_right"> </div>

                    <div class="park_wrapper_form">
                        <img class="park_form_logo zoomInDown" src="images/dark_logo.svg" alt="Company Logo" />   
                        <div class="park_input_group">
                            <i class="mdi mdi-account"></i>
                            <input type="text" class="park_input_group_input iconic" name="uname" value={'twsc_user@apsissolutions.com'} placeholder='Username or email address' required />
                                <span class="park_input_group_highlight"></span>
                                <span class="park_input_group-bar"></span>
                                {/* <label class="park_input_group_label iconic">Username or email address</label> */}
                        </div>
                        <div class="park_input_group">
                            <i class="mdi mdi-lock"></i>
                            <input type="password" class="park_input_group_input iconic" value={'Sh:I96};T?p~b:F'} name="pass" placeholder="Password" required />
                                <span class="park_input_group_highlight"></span>
                                <span class="park_input_group-bar"></span>
                                {/* <label class="park_input_group_label iconic">Password</label> */}
                        </div>
                        <button class="park_form_button"> <span>Login</span> </button>
                    </div>

                    <div class="park_wind-turbine big">
                        <div class="park_wind-turbine__inner">  </div>
                    </div>
                    <div class="park_wind-turbine">
                        <div class="park_wind-turbine__inner"> 
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
} 
