import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

export default function BasePage() { 
    const Home = lazy(() => import("./landing/Home"));
    const Transition = lazy(() => import("./landing/Transition"));
    const Intro = lazy(() => import("./landing/Intro"));
    const WhoWeAre = lazy(() => import("./landing/WhoWeAre"));
    const WhatWeDo = lazy(() => import("./landing/WhatWeDo"));
    const LatestNews = lazy(() => import("./landing/NewsSection"));

    const WhoWeArePage = lazy(() => import("./who-we-are"));
    const WhatWeDoPage = lazy(() => import("./what-we-do"));
    const PortfolioPage = lazy(() => import("./our-portfolio"));
    const NewsPage = lazy(() => import("./news"));
    const PrivacyPolicy = lazy(() => import("./Privacy-policy"));
    const TermsOfUse = lazy(() => import("./terms-of-use"));
    const ContactUsPage = lazy(() => import("./contact-us"));
    const FaqsPage  = lazy(() => import("./faqs"));
    const LoginPage  = lazy(() => import("./login"));
    const PageNotFound = lazy(() => import("./404"));

    return (
        <Suspense>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Transition" element={<Transition />} />
                <Route path="/Intro" element={<Intro />} />
                <Route path="/who_we_are" element={<WhoWeAre />} />
                <Route path="/WhatWeDo" element={<WhatWeDo />} />
                <Route path="/LatestNews" element={<LatestNews />} />
                <Route path="/who-we-are" element={<WhoWeArePage />} />
                <Route path="/what-we-do" element={<WhatWeDoPage />} />
                <Route path="/our-portfolio" element={<PortfolioPage />} />
                <Route path="/news" element={<NewsPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="/faqs" element={<FaqsPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/404" element={<PageNotFound />} />
            </Routes>
        </Suspense>
    );
}