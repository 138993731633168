import { Fragment, useEffect, useState } from "react";
import { LightNavBar, DarkNavBar } from "../header";
import { useLocation } from 'react-router-dom'
import LoginPage from "../../pages/login";

export default function Layout({ children }) {
    const [url, setUrl] = useState()

    const path = useLocation()

    useEffect(() => {
        const pageUrl = path.pathname.split("/")[1];
        setUrl(pageUrl)
    }, [path])

    // useEffect(() => {
    //     const userinfo = localStorage.getItem('userinfo');
    //     if (!userinfo && typeof url !== 'undefined' && url !== 'login') {
    //         // alert(url)
    //         window.location.replace('/login');
    //     }
    // });


    return (
        <Fragment>
            { 
                (url === 'who_we_are' || url === 'WhatWeDo' || url === 'NewsSection' || url === 'LatestNews' || url === 'faqs' || url === 'our-portfolio' ?
                    <DarkNavBar /> : <LightNavBar />)
            }
            {/* {
                url !== 'login' &&
                (url === 'who_we_are' || url === 'WhatWeDo' || url === 'NewsSection' || url === 'LatestNews' || url === 'faqs' || url === 'our-portfolio' ?
                    <DarkNavBar /> : <LightNavBar />)
            } */}
            {children}
        </Fragment>
    )
} 